.badge-container {
  min-height: 10vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  box-shadow: 0px -2px 5px 0px rgba(66, 68, 90, 1);
  margin-right: -0.05rem;
  margin-left: -0.05rem;
  padding-left: 0.5rem;
}
.badge-container > .user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
  p {
    margin-top: 0;
  }
  h2 {
    margin-top: 1rem;
    margin-bottom: 0;

    text-align: center;
    display: flex;
    align-items: center;
  }
}

.badge-container > .avatar-container > .avatar {
  overflow: visible;
  margin: 0.5rem;
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}

.badge-container > h1 {
  text-align: center;
  width: 60vw;
}

div.convrsation-users-list-container {
  margin-left: auto;
  margin-right: 2rem;
}
