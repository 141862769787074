.conversation-list-item-container {
  display: flex;
  flex-direction: row;
}

div > .conversation-avatar {
  background-color: rgb(152, 189, 152);
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 0.5rem;
  font-size: 1.75rem;
  overflow: visible;
}

.message-unread {
  font-weight: bold;
}

.dot.message-unread {
  width: 0.75rem;
  height: 0.75rem;
  background-color: #3578e5;
  border-radius: 50%;
}
