.search-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem;
  margin-bottom: 0;
  h4 {
    color: #888;
  }
}
