.conversation-list-container {
  overflow: auto;
}

.converstaion-header {
  display: flex;
}
.converstaion-header > h2 {
  padding-left: 1rem;
  font-weight: 700;
}
.converstaion-header > .room-creation-form {
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  margin-right: 1rem;
}
