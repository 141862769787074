.sender-form {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 1rem;
}

.sender-container {
  min-height: 2rem;

  background-color: #d3d3d3;
  border-radius: 20px;
  width: 65vw;
  padding: 0.3rem;
}

.sender-textbox {
  max-height: 15vh;
  overflow: auto;
  margin-right: 1.2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  padding: 0.1rem 1rem;
  word-break: break-word;
}

.sender-textbox:focus {
  outline: none;
}
.sender-container:focus-within {
  outline: #444 solid 2px;
}
