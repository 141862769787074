.message-container {
  display: flex;
  flex-direction: row; /* Default value, overridden conditionally below */
  word-break: break-word;
  align-items: flex-end;
}

.my-message-container {
  flex-direction: row-reverse;
}

.message-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.message {
  display: flex;
  align-items: flex-end;

  max-width: 80%;
  margin: 0.2rem 0.5rem;
  padding: 0.33rem 1rem;
  font-size: 16px;
  line-height: 1.4;
}

.me-message {
  align-self: flex-end;
  background-color: #007bff;
  color: #fff;
}

.guest-message {
  align-self: flex-start;
  background-color: #f0f0f0;
  color: #333;
}

.first-me {
  border-radius: 20px 20px 8px 20px;
}

.first-guest {
  border-radius: 20px 20px 20px 8px;
}

.middle-me {
  border-radius: 20px 8px 8px 20px;
}

.middle-guest {
  border-radius: 8px 20px 20px 8px;
}

.last-me {
  border-radius: 20px 8px 20px 20px;
}

.last-guest {
  border-radius: 8px 20px 20px 20px;
}

.single-me,
.single-guest {
  border-radius: 20px;
}

.sender {
  font-size: 0.7rem;
  font-weight: 300;
  padding: 0;
  margin-left: 0.5rem;
  margin: 0;
}

div.message-container > .avatar {
  height: 1.75rem;
  width: 1.75rem;
  font-size: 1.1rem;
}
.visible-avatar {
  opacity: 1;
}
.invisible-avatar {
  opacity: 0;
}

.status {
  font-size: 0.7rem;
  font-weight: 300;
  padding: 0;
  width: 100%;
  margin: 0;
  left: 0.5rem;
  text-align: right;
}

.last-message-guest {
  margin-bottom: 0.25rem;
}

.message-time {
  font-size: 0.7rem;
  font-weight: 300;
  padding: 0;
  margin-left: 0.5rem;
  margin: 0;
  text-align: center;
}

.typing__dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 8px 4px;
  background: #8d8c91;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}
