.avatar-container {
  position: relative;
}
.avatar-online-indicator {
  position: absolute;
  bottom: -0.2rem;
  right: -0.2rem;
  width: 25%;
  height: 25%;
  border-radius: 50%;
  background-color: green;
  border: 2px solid #fff;
}

.avatar-ofline-indicator {
  position: absolute;
  bottom: -0.3rem;
  right: -0.3rem;
  border-radius: 2rem;
  background-color: rgb(87, 162, 87);
  border: 2px solid #fff;
  font-size: 35%;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 0.1rem 0.4rem;
  align-items: center;
}

.avatar-ofline-notext-indicator {
  position: absolute;
  bottom: -0.2rem;
  right: -0.2rem;
  width: 80%;
  height: 0.8rem;
  border-radius: 50%;
  background-color: rgb(195, 195, 195);
  border: 2px solid rgb(231, 255, 231);
}
