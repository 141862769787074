.user-list-item-container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.user-list-item-container > .avatar-container > .avatar {
  width: 3rem;
  height: 3rem;
  font-size: 1.75rem;
  margin-bottom: 0.6rem;
  overflow: visible;
}

.user-list-item-container > .user-list-item-info {
  margin-left: 0.5rem;
  padding-bottom: 0.6rem;
  border-bottom: 1px solid #ddd;
  width: 100%;
  h3 {
    margin: 0;
  }
  h5 {
    margin: 0;
    font-weight: lighter;
    font-size: 75%;
  }
}

span.users-loadmore-button {
  margin: 1rem 7.5rem 0 7.5rem;
  text-decoration: text-decoration-line;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  color: #999;
  text-decoration: none;
  transition: background-color 0.3s;
  cursor: pointer;
}

span.users-loadmore-button:hover {
  background-color: #efefef;
}

span.users-loadmore-button:active {
  background-color: #d9d9d9;
}
