.dialog-title {
  font-weight: bold;
}
.users-list {
  max-height: 28vh;
  max-width: 15vw;
  grid-column: 1;
}
.selected-users-list {
  max-height: 28vh;
  max-width: 15vw;
  grid-column: 2;
}

.dialog-users-list-container {
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.selected-container {
  max-height: 20vh;
}
.all-users-container {
  max-height: 35vh;
}

.dialog-container {
  display: block;
}
